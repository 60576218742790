<template>
  <BaseFilterDialogAndChips
    :submitted-values="submittedValues"
    :show-reset-button="showResetButton"
    @open="copyFilterParams"
    @clear:filter="clearFilter"
    @reset="resetFilters"
    @submit="submitFilters"
  >
    <v-row dense>
      <v-col cols="12">
        <ProjectAutocomplete
          v-model="params.project_id"
          @update:initial-item="updateAutocompleteValue('project_id', $event)"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <BaseDatepickerInput v-model="params.date_from" :label="$t('general.date_and_time.from')" />
      </v-col>

      <v-col cols="12" sm="6">
        <BaseDatepickerInput v-model="params.date_to" :label="$t('general.date_and_time.to')" />
      </v-col>
    </v-row>
  </BaseFilterDialogAndChips>
</template>

<script>
import BaseFilterDialogAndChips from '@/components/base/BaseFilterDialogAndChips';
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput';
import { getDefaultProjectOrderStatisticsFilterParams } from '@/store/modules/project-order-statistics-module';
import filterMixin from '@/mixins/filter-mixin';
import ProjectAutocomplete from '@/components/autocompletes/ProjectAutocomplete';

export default {
  name: 'OrderStatisticsFilter',

  components: { ProjectAutocomplete, BaseDatepickerInput, BaseFilterDialogAndChips },

  mixins: [filterMixin],

  data() {
    return {
      defaultParams: getDefaultProjectOrderStatisticsFilterParams(),
    };
  },

  computed: {
    submittedValues() {
      const values = [];
      const { date_from, date_to, project_id } = this.filterParams;

      if (project_id && this.autocompleteValues.project_id?.id === project_id) {
        values.push({
          key: 'project_id',
          value: this.autocompleteValues.project_id.title,
          label: this.$t('general.project'),
        });
      }

      if (date_from) {
        values.push({
          key: 'date_from',
          value: date_from,
          label: this.$t('general.date_and_time.from'),
        });
      }

      if (date_to) {
        values.push({
          key: 'date_to',
          value: date_to,
          label: this.$t('general.date_and_time.to'),
        });
      }

      return values;
    },
  },
};
</script>

<style scoped></style>
