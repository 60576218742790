<template>
  <div>
    <div class="d-flex align-center justify-end px-4">
      <ProjectOrderStatisticsFilter
        :filter-params="projectOrderStatisticsFilterParams"
        @change="$router.push({ name: 'projectOrderStatistics', query: $event })"
        @reset="resetProjectOrderStatisticsFilters"
      />
    </div>

    <div :class="{ 'content-loading': loading['get:api/project-issues-statistic'] }">
      <div class="pa-3">
        <HorizontalBarChart :chart-data="chartData" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { getSanitizedFilterParams } from '@/util/filter-params';
import HorizontalBarChart from '@/charts/HorizontalBarChart';
import ProjectOrderStatisticsFilter from '@/components/filters/ProjectOrderStatisticsFilter';
import { getDefaultProjectOrderStatisticsFilterParams } from '@/store/modules/project-order-statistics-module';

export default {
  name: 'ProjectOrderStatistics',

  components: { HorizontalBarChart, ProjectOrderStatisticsFilter },

  computed: {
    ...mapState('projectOrderStatistics', [
      'projectOrderStatistics',
      'projectOrderStatisticsFilterParams',
    ]),
    ...mapGetters(['loading']),

    chartData() {
      const labels = [];
      const orders = [];

      for (let i = 0; i < this.projectOrderStatistics.length; i++) {
        const project = this.projectOrderStatistics[i];

        labels.push(project.title || '-');
        orders.push(project.total_price);
      }

      return {
        labels,
        datasets: [
          {
            label: this.$t('general.orders_total'),
            backgroundColor: this.$vuetify.theme.currentTheme.success,
            data: orders,
          },
        ],
      };
    },
  },

  created() {
    this.fetchProjectOrderStatistics(getSanitizedFilterParams(this.$route.query));
  },

  beforeRouteUpdate(to, from, next) {
    const params = getSanitizedFilterParams(to.query);
    if (
      JSON.stringify(this.projectOrderStatisticsFilterParams) !== JSON.stringify(params) &&
      to.name === 'projectOrderStatistics'
    ) {
      this.fetchProjectOrderStatistics(to.query);
    }
    next();
  },

  methods: {
    ...mapActions('projectOrderStatistics', ['fetchProjectOrderStatistics']),

    resetProjectOrderStatisticsFilters() {
      const defaultFilters = getDefaultProjectOrderStatisticsFilterParams();
      if (
        JSON.stringify(defaultFilters) === JSON.stringify(this.projectOrderStatisticsFilterParams)
      ) {
        return;
      }
      this.$router.push({ name: 'projectOrderStatistics', query: defaultFilters });
    },
  },
};
</script>

<style scoped></style>
